export default
[
    {title: "OilPrices.Label.key", dataIndex: "key", key: "key", width: "100px"},
    {title: "OilPrices.Label.oilPricesProductDescriptionAR", dataIndex: "productDescriptionAR", key: "productDescriptionAR", width: "100px"},
    {title: "OilPrices.Label.oilPricesProductDescriptionEN", dataIndex: "productDescriptionEN", key: "productDescriptionEN", width: "100px"},
    {title: "OilPrices.Label.oilPricesProductCostPrice", dataIndex: "productCostPrice", key: "productCostPrice", width: "100px"},
    {title: "OilPrices.Label.oilPricesProductSelingPrice", dataIndex: "productSelingPrice", key: "productSelingPrice", width: "100px"},
  
    {title: "OilPrices.Label.oilPricesproductLiter", dataIndex: "productLiter", key: "productLiter", width: "100px"},
    {title: "OilPrices.Label.oilPricesPetrolCompanyid", dataIndex: "petrol_Company_id", key: "petrol_Company_id", width: "100px"}
]

