import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import GetCompanyBranchStatementViewModel from "../view-models/GetCompanyBranchStatementViewModel";
import ListCompanyViewModel from "../../../entities/companies/view-models/ListCompanyViewModel";
import ListBranchViewModel from "../../../entities/branches/view-models/ListBranchViewModel";
import NavigationService from "app/services/NavigationService";
import UserContext from "identity/contexts/UserContext";

export default class CompanyBranchStatementStore
{
    getCompanyBranchStatementViewModel: GetCompanyBranchStatementViewModel;
    listCompanyViewModel: ListCompanyViewModel;
    listBranchViewModel: ListBranchViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onCompanyBranchStatementGetPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 5 && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.getCompanyBranchStatementViewModel = new GetCompanyBranchStatementViewModel(this);
        this.listCompanyViewModel = new ListCompanyViewModel();
        this.listBranchViewModel = new ListBranchViewModel();
    }

    onCompanyBranchStatementGetPageUnload()
    {
        this.getCompanyBranchStatementViewModel = null;
        this.listCompanyViewModel = null;
        this.listBranchViewModel = null;
    }

}
