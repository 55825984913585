import NavigationService from "app/services/NavigationService";
import {AppStore} from "app/stores/AppStore";
import UserContext from "identity/contexts/UserContext";
import {makeAutoObservable} from "mobx";
import EditAppSettingViewModel from "../view-models/EditAppSettingViewModel";

export default class AppSettingStore
{
    editAppSettingViewModel: EditAppSettingViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onAppSettingEditPageLoad()
    {
        if( UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.editAppSettingViewModel = new EditAppSettingViewModel();
    }

    onAppSettingEditPageUnload()
    {
        if( UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.editAppSettingViewModel = null;
    }

}
