import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import EditCarUserViewModel from "../view-models/EditCarUserViewModel";
import GetCarUserViewModel from "../view-models/GetCarUserViewModel";

import UserContext from "identity/contexts/UserContext";
import NavigationService from "app/services/NavigationService";

export default class CarUserStore
{
    getCarUserViewModel: GetCarUserViewModel;
    editCarUserViewModel: EditCarUserViewModel;

  


   
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onCarUserGetPageLoad()
    {
        
        this.getCarUserViewModel = new GetCarUserViewModel(this);

        
    }

    onCarUserGetPageUnload()
    {
      
        this.getCarUserViewModel = null;
    }

    onCarUserEditPageLoad()
    {
      
        this.editCarUserViewModel = new EditCarUserViewModel(this);
       
    }

    onCarUserEditPageUnload()
    {
        this.editCarUserViewModel = null;
       
    }

}
