import ApiResponse from "app/models/ApiResponse";
import ApiService from "app/services/ApiService";
import Endpoints from "app/constants/Endpoints";

import GetOilPriceRequest from "./GetOilPriceRequest";

export default class GetOilPriceHandler
{
    public static async get(request: GetOilPriceRequest): Promise<ApiResponse>
    {
        let response = await ApiService.post(Endpoints.apiOilPriceGet, request, true);
        return response;
    }
}
