import {AppStore} from "app/stores/AppStore";

import {makeAutoObservable} from "mobx";
import EditPetrolCompanyViewModel from "../view-models/EditPetrolCompanyViewModel";
import GetPetrolCompanyViewModel from "../view-models/GetPetrolCompanyViewModel";
import UserContext from "identity/contexts/UserContext";
import NavigationService from "app/services/NavigationService";

export default class PetrolCompaniesStore
{
    getPetrolCompanyViewModel: GetPetrolCompanyViewModel;
    editPetrolCompanyViewModel: EditPetrolCompanyViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onPetrolCompanyGetPageLoad()
    {

        if( UserContext.info.role != 100  )
            {
               
        
                 NavigationService.navigate('/');
                
        
        }
        this.getPetrolCompanyViewModel = new GetPetrolCompanyViewModel(this);

        

    }

    onPetrolCompanyGetPageUnload()
    {
        this.getPetrolCompanyViewModel = null;
    }

    onPetrolCompanyEditPageLoad()
    {

        if( UserContext.info.role != 100  )
            {
               
        
                 NavigationService.navigate('/');
                
        
        }
        this.editPetrolCompanyViewModel = new EditPetrolCompanyViewModel(this);
    }

    onPetrolCompanyEditPageUnload()
    {
        this.editPetrolCompanyViewModel = null;
    }

}
