import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import EditPetroStationViewModel from "../view-models/EditPetroStationViewModel";
import GetPetroStationViewModel from "../view-models/GetPetroStationViewModel";
import PaymentPetroStationViewModel from "../view-models/PaymentPetroStationViewModel";
import ListPetropayAccountViewModel from "../../PetropayAccounts/view-models/ListPetropayAccountViewModel";
import ListPetrolCompanyViewModel from "../../petrol-companies/view-models/ListPetrolCompanyViewModel";
import UserContext from "identity/contexts/UserContext";
import NavigationService from "app/services/NavigationService";

export default class PetroStationStore
{
    getPetroStationViewModel: GetPetroStationViewModel;
    editPetroStationViewModel: EditPetroStationViewModel;
    paymentPetroStationViewModel: PaymentPetroStationViewModel;
    listPetropayAccountViewModel: ListPetropayAccountViewModel;
    listPetrolCompanyViewModel: ListPetrolCompanyViewModel;

    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onPetroStationGetPageLoad()
    {
        if( UserContext.info.role !== 100 && UserContext.info.role !== 10 )
            {
                 NavigationService.navigate('/');
        }


        this.getPetroStationViewModel = new GetPetroStationViewModel(this);
    }

    onPetroStationGetPageUnload()
    {
        this.getPetroStationViewModel = null;
    }

    onPetroStationEditPageLoad()
    {
        if( UserContext.info.role !== 100 && UserContext.info.role !== 10 )
            {
                 NavigationService.navigate('/');
        }
        
        this.editPetroStationViewModel = new EditPetroStationViewModel(this);
        this.listPetrolCompanyViewModel = new ListPetrolCompanyViewModel();
    }

    onPetroStationEditPageUnload()
    {
        this.editPetroStationViewModel = null;
        this.listPetrolCompanyViewModel = null;
    }

    onPetroStationPaymentPageLoad()

    {

        if( UserContext.info.role !== 100 && UserContext.info.role !== 10 )
        {
             NavigationService.navigate('/');
    }
    
        this.paymentPetroStationViewModel = new PaymentPetroStationViewModel(this);
        this.listPetropayAccountViewModel = new ListPetropayAccountViewModel();
    }

    onPetroStationPaymentPageUnload()
    {
        this.paymentPetroStationViewModel = null;
        this.listPetropayAccountViewModel = null;
    }

}
