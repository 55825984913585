export default class GetNewCustomerRequest
{
    pageSize: number;
    pageIndex: number;
    dateFrom: string;
    dateTo: string;
    status: number;
    exportToFile: boolean;
    constructor(
    ) {
    }
}
