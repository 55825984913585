import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import EditSubscriptionViewModel from "../view-models/EditSubscriptionViewModel";
import GetSubscriptionViewModel from "../view-models/GetSubscriptionViewModel";
import ListBundleViewModel from "../../bundles/view-models/ListBundleViewModel";
import ListPetropayAccountViewModel from "../../PetropayAccounts/view-models/ListPetropayAccountViewModel";
import ListCarViewModel from "../../cars/view-models/ListCarViewModel";
import SubscriptionInvoice from "../components/invoice/SubscriptionInvoice";
import InvoiceSubscriptionViewModel from "../view-models/InvoiceSubscriptionViewModel";
import UserContext from "identity/contexts/UserContext";
import NavigationService from "app/services/NavigationService";
import ListCompanyViewModel from "entities/companies/view-models/ListCompanyViewModel";


export default class SubscriptionStore
{
    getSubscriptionViewModel: GetSubscriptionViewModel;
    editSubscriptionViewModel: EditSubscriptionViewModel;
    listBundleViewModel: ListBundleViewModel;
    listPetropayAccountViewModel: ListPetropayAccountViewModel;
    listCarViewModel: ListCarViewModel;
    listCompanyViewModel: ListCompanyViewModel;

    invoiceSubscriptionViewModel: InvoiceSubscriptionViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onSubscriptionInvoicePageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }

        this.invoiceSubscriptionViewModel = new InvoiceSubscriptionViewModel();
        
    }

    onSubscriptionInvoicePageUnload()
    {
        this.invoiceSubscriptionViewModel = null;
    }

    onSubscriptionGetPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.getSubscriptionViewModel = new GetSubscriptionViewModel(this);
    }

    onSubscriptionGetPageUnload()
    {
        this.getSubscriptionViewModel = null;
    }

    onSubscriptionEditPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.listPetropayAccountViewModel = new ListPetropayAccountViewModel();
        this.editSubscriptionViewModel = new EditSubscriptionViewModel(this);
        this.listBundleViewModel = new ListBundleViewModel();
        this.listCompanyViewModel = new ListCompanyViewModel();
    }

    onSubscriptionEditPageUnload()
    {
        this.editSubscriptionViewModel = null;
        this.listBundleViewModel = null;
        this.listPetropayAccountViewModel = null;
    }
    onCarAddSubscriptionPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.listCarViewModel = new ListCarViewModel();
        this.editSubscriptionViewModel = new EditSubscriptionViewModel(this);
    }

    onCarAddSubscriptionPageUnload()
    {
        this.editSubscriptionViewModel = null;
        this.listCarViewModel = null;
    }

}
