
import {makeAutoObservable} from "mobx";

export default class AddCarUserRequest
{
     carUserName: string;
     carUserEmpID :number;
    carUserPhoneNumber: string;
    carUserPassword: number;
    carID: number  ;
    // carUserMonthlyBudget: number;
    

    constructor(
    ) {
        makeAutoObservable(this);
    }
}
