import NavigationService from "app/services/NavigationService";
import {AppStore} from "app/stores/AppStore";
import UserContext from "identity/contexts/UserContext";
import {makeAutoObservable} from "mobx";
import EditNewCustomerViewModel from "../view-models/EditNewCustomerViewModel";
import GetNewCustomerViewModel from "../view-models/GetNewCustomerViewModel";

export default class NewCustomerStore
{
    getNewCustomerViewModel: GetNewCustomerViewModel;
    editNewCustomerViewModel: EditNewCustomerViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onNewCustomerGetPageLoad()
    {
        if( UserContext.info.role !== 100 )
        {    
             NavigationService.navigate('/');
    
         }
        this.getNewCustomerViewModel = new GetNewCustomerViewModel(this);
    }

    onNewCustomerGetPageUnload()
    {
        this.getNewCustomerViewModel = null;
    }

    onNewCustomerEditPageLoad()
    {
        this.editNewCustomerViewModel = new EditNewCustomerViewModel(this);
        if( UserContext.info.role !== 100 )
        {    
             NavigationService.navigate('/');
    
         }
    }

    onNewCustomerEditPageUnload()
    {
        this.editNewCustomerViewModel = null;
    }

}
