export default class GetStationUserRequest
{
    public stationCompanyId: number;
    public stationId: number;
    public pageSize: number;
    public pageIndex: number;
    constructor(
    ) {
    }
}
