import React, {Component, useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import Stores from "app/constants/Stores";
import PageStore from "page/stores/PageStore";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import "./Page.scss";
import UserContext from "identity/contexts/UserContext";
import RoleType from "identity/constants/RoleType";
import { Layout, Menu, Breadcrumb } from 'antd';
import Sidebar from "./sidebar/Sidebar";
import ContentHeader from "./content/ContentHeader";
import i18next from "i18next";
const { Header, Content, Footer} = Layout;


interface PageProps
{
    pageStore?: PageStore
}

const Page: React.FC<PageProps> = inject(Stores.pageStore)(observer(({pageStore, children}) =>
{
    return (
        <div >
            <Layout style={{ minHeight: '100vh' }} >
                <Sidebar />
                <Layout className="site-layout">
                    <ContentHeader />
                    <Content style={{ margin: '0 16px' }}>
                        <br/>
                        {/*<Breadcrumb style={{ margin: '16px 0' }}>
                            <Breadcrumb.Item>User</Breadcrumb.Item>
                            <Breadcrumb.Item>Bill</Breadcrumb.Item>
                        </Breadcrumb>*/}
                        <div className="site-layout-background" style={{ padding: 30, minHeight: 360 }}>
                            {children}
                        </div>
                    </Content>


                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>                              
                   
                    
                    
                    <Footer  style={ localStorage.getItem("currentLanguage") == 'en' ? {textAlign:"right"} : {textAlign:"left"} }>{i18next.t("App.Footer")}
                    
                    <a  href="https://www.facebook.com/petropay.link/" className="fa fa-facebook"></a>
                    <a href="https://twitter.com/PETROPAY1" className="fa fa-twitter"></a>
                    <a href="https://www.linkedin.com/company/%D8%AA%D8%B1%D9%88%D9%81%D8%AC%D9%86-%D8%A7%D9%84%D8%AA%D8%AC%D8%A7%D8%B1%D9%8A%D9%87-true-vision-commercial/" className="fa fa-linkedin"></a>
                    <a href="https://www.instagram.com/petropayservice/" className ="fa fa-instagram"></a>
                    <a href="https://www.youtube.com/channel/UCtVI1tP1oEZ3dtx07m5a9vg" className="fa fa-youtube"></a>
                    
                    </Footer>

                   
                </Layout>
            </Layout>
        </div>
    )
}));

export default withRouter(withTranslation()(Page));
