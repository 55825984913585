import ApiResponse from "app/models/ApiResponse";
import ApiService from "app/services/ApiService";
import Endpoints from "app/constants/Endpoints";
import DetailOilPriceRequest from "./DetailOilPriceRequest";


export default class DetailOilPriceHandler
{
    public static async detail(request: DetailOilPriceRequest): Promise<ApiResponse>
    {
        let response = await ApiService.post(Endpoints.apiOilPriceDetail, request, true);
        return response;
    }
}
