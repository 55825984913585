export default class GetCarUserRequest
{
      
    constructor(
       
        public pageSize: number,
        public pageIndex: number,
        
        public carId: number
       
    ) {
    }
}
