export default
[
     {title: "CarUsers.Label.key", dataIndex: "key", key: "key", responsive: ['md']},
    
    {title: "CarUsers.Label.carUserName", dataIndex: "carUserName", key: "carUserName", responsive: ['md']},
    {title: "CarUsers.Label.carUserPassword", dataIndex: "carUserPassword", key: "carUserPassword", responsive: ['md']},
    {title: "CarUsers.Label.carUserPhone", dataIndex: "carUserPhoneNumber", key: "carUserPhoneNumber", responsive: ['md']},
    // {title: "CarUsers.Label.carId", dataIndex: "carID", key: "carID", responsive: ['md']},
    
]

