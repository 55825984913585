import IDeserialize from "app/interfaces/deserialize";
import {makeAutoObservable} from "mobx";
import PricePackageItem from "./PricePackageItem";

export default class PetrolPriceResponse implements IDeserialize
{
    items: PricePackageItem[];

    constructor() {
        makeAutoObservable(this);
    }

    deserialize(input: any): this
    {
        Object.assign(this, input);

        this.items = this.items.map(x => new PricePackageItem().deserialize(x));

        return this;
    }
}
