import React from 'react';
import {inject, observer} from "mobx-react";
import Stores from "app/constants/Stores";
import {useParams} from "react-router-dom";
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    PageHeader,
    Radio,
    Row, Select,
    Spin
} from "antd";
import i18next from "i18next";
import EditCarUserRequest from "../../handlers/edit/EditCarUserRequest";
import DetailCarUserResponse from "../../handlers/detail/DetailCarUserResponse";
import AddCarUserRequest from "../../handlers/add/AddCarUserRequest";
import {
    PlusOutlined, EyeInvisibleOutlined, EyeTwoTone
} from '@ant-design/icons';
import history from "../../../../app/utils/History";
import CarUserStore from "../../stores/CarUserStore";
import { PasswordInput } from 'antd-password-input-strength';
import { MaskedInput } from 'antd-mask-input';
import UserContext from "../../../../identity/contexts/UserContext";
import GetCarRequest from 'entities/cars/handlers/get/GetCarRequest';
const {useEffect} = React;
const { Option } = Select;

interface EditCarUserProps {
    carUserStore?: CarUserStore;
    match?: any;
}

const EditCarUser: React.FC<EditCarUserProps> = inject(Stores.carUserStore)(observer(({carUserStore, match}) =>
{
    const [dataFetched, setDataFetched] = React.useState(false);
    const [carUserId, setCarUserId] = React.useState(0);
    const [petroCarOptions, setPetroCarOptions] = React.useState([]);


    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };


    useEffect(() => {
        onLoad();
        return onUnload;
    }, []);

    async function onLoad()
    {
        carUserStore.onCarUserEditPageLoad();
        let carUserIdParam = +match.params?.carUserId;

        if(carUserIdParam)
        {
            await carUserStore.editCarUserViewModel.getDetailCarUser(carUserIdParam);
        }
        else{
            carUserStore.editCarUserViewModel.addCarUserRequest = new AddCarUserRequest();
            carUserStore.editCarUserViewModel.detailCarUserResponse = new DetailCarUserResponse();
        }
        if(UserContext.info.role === 10) {
          
            let petroCarOptions = [];
           
            setPetroCarOptions(petroCarOptions);
        }

        setCarUserId(carUserIdParam);
        setDataFetched(true);
    }
    let viewModel = carUserStore.editCarUserViewModel;

    if(!viewModel) return;


    async function onFinish(values: any) {

        if(carUserId)
        {
            await viewModel.editCarUser(viewModel.editCarUserRequest);
        }
        else
        {
            await viewModel.addCarUser(viewModel.addCarUserRequest);
        }
        if(!viewModel.errorMessage)
            history.goBack();
    };
    function onUnload() {
        carUserStore.onCarUserEditPageUnload();
    }
    function onChanged(e){
        if(carUserId)
            viewModel.editCarUserRequest[`${e.target.id}`] = e.target.value;
        else
            viewModel.addCarUserRequest[`${e.target.id}`] = e.target.value;
            viewModel.addCarUserRequest.carID=GetCarRequest.carID;  //   to set the defulte car id from select carid
    }
    function onMaskChanged(e){
        if(carUserId)
            viewModel.editCarUserRequest[`${e.target.id}`] = e.target.value.replace(/\s+/g, '');
        else
            viewModel.addCarUserRequest[`${e.target.id}`] = e.target.value.replace(/\s+/g, '');
    }
    function onCheckboxChange(e){
        if(carUserId)
            viewModel.editCarUserRequest[`${e.target.id}`] = e.target.checked;
        else
            viewModel.addCarUserRequest[`${e.target.id}`] = e.target.checked;
    }
    function onSelectChanged(e, propName) {

        if(carUserId)
            viewModel.editCarUserRequest[`${propName}`] = e;
        else
            viewModel.addCarUserRequest[`${propName}`] = e;
    }
        /*function onSwitchChange(e){
            if(carUserId)
                carUserStore.editCarUserViewModel.editCarUserRequest.companyCarUserActiva = e;
            else
                carUserStore.editCarUserViewModel.addCarUserRequest.companyCarUserActiva = e;
        }*/

    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={carUserId ? `${i18next.t("CarUsers.Page.SubTitle")} ${carUserId}` : i18next.t("CarUsers.Page.SubTitle")}
            />


           
            {dataFetched ?

            
            <Form {...formItemLayout} layout={"vertical"} onFinish={onFinish} form={form}
                  key={"carUserForm"}
                 scrollToFirstError>
                <Row gutter={[24, 16]}>
                   
                  
                <Divider>{i18next.t("Cars.Section.DriverInformation")}</Divider>
                    <Col span={8}>
                <Form.Item name="carUserName" initialValue={viewModel?.detailCarUserResponse?.carUserName}
                           key={"carUserName"}
                           label={i18next.t("CarUsers.Label.carUserName")}
                           rules={[
                               {
                                   required: true,
                                   message: i18next.t("CarUsers.Validation.Message.carWorkerFname.Required")
                               }
                           ]}>
                    <Input onChange={onChanged}/>
                </Form.Item>
                    </Col>


                    <Col span={8}>
                <Form.Item name="carUserEmpID" initialValue={viewModel?.detailCarUserResponse?.carUserEmpID}
                           key={"carUserEmpID"}
                           label={i18next.t("CarUsers.Label.carUserEmpID")}
                          >
                    <Input onChange={onChanged}/>
                </Form.Item>
                    </Col>

                    <Col span={8}>
                <Form.Item name="carUserPhoneNumber" initialValue={viewModel?.detailCarUserResponse?.carUserPhoneNumber}
                           key={"carUserPhoneNumber"}
                           label={i18next.t("CarUsers.Label.carUserPhone")}
                          >
                    {/*<Input onChange={onChanged}/>*/}
                    <MaskedInput className={"phone-number"} mask="+2 111 111 11111" onChange={onMaskChanged}/>
                </Form.Item>
                    </Col>

                  

                  
                    <Col span={8}>
                
                    </Col>
                    <Col span={8}>
                        <Form.Item name="carUserPassword" initialValue={viewModel?.detailCarUserResponse?.carUserPassword}
                                   key={"carUserPassword"}
                                   label={i18next.t("CarUsers.Label.carUserPassword")}
                                   rules={[
                                    {
                                        required: true,
                                        message: i18next.t("Cars.Validation.Message.carDriverConfirmationCode.Required")
                                    }
                                ]}>
                           <Input 
                                onChange={onChanged}
                            />
                        </Form.Item>
                    </Col>
                </Row>


                {/* <Col span={8}>
                <Form.Item name="carID" initialValue={viewModel?.detailCarUserResponse?.carID}
                           key={"carID"}
                           label={i18next.t("CarUsers.Label.carID")}
                          >
                    <Input onChange={onChanged}/>
                </Form.Item>
                    </Col> */}

                <Divider></Divider>
                {viewModel.errorMessage && (
                    <div className='response-error-msg'>{viewModel.errorMessage}</div>
                )}
                    <PageHeader
                        ghost={false}
                        extra={[
                            <Button className={"button-section"} type="primary" loading={viewModel.isProcessing} key="submit" size={"large"} htmlType="submit">
                                {i18next.t("General.Add.SaveButton")}
                            </Button>
                        ]}
                    />

            </Form>
                :
                <Row gutter={[24, 16]}>
                    <Col offset={11} span={8}>
                        <Spin className={"spine"} size="large" />
                    </Col>
                </Row>
            }

        </div>
    )
}));

export default EditCarUser;
