export default
[
   // {title: "CustomerDashboard.CompanyBranchItem.Label.key", dataIndex: "key", key: "key"},
    {title: "CustomerDashboard.CompanyBranchItem.Label.branchName", dataIndex: "branchName", key: "branchName"},
    {title: "CustomerDashboard.CompanyBranchItem.Label.branchBalance", dataIndex: "branchBalance",
    render: value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser:value => value!.replace(/\$\s?|(,*)/g, '') ,
    key: "branchBalance"},
   
    {title: "CustomerDashboard.CompanyBranchItem.Label.carBalance", dataIndex: "branchCarBalance",
    render: value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser:value => value!.replace(/\$\s?|(,*)/g, '') ,
    key: "branchCarBalance"},

    {title: "CustomerDashboard.Section.ActiveCars", dataIndex: "branchActiveCarCount", key: "branchActiveCarCount"},
    {title: "CustomerDashboard.Section.InActiveCars", dataIndex: "branchInActiveCarCount", key: "branchInActiveCarCount"}


    

]

