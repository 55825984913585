import {makeAutoObservable} from "mobx";

export default class EditCarUserRequest
{
    carUserID: number;
    carUserName: string;
    carUserEmpID :number;
  carUserPhoneNumber: string;
  carUserPassword: number;
   carID: number;
   // carUserMonthlyBudget: number;



    constructor(
    ) {
        makeAutoObservable(this);
    }
}
