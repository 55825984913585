import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import PaymentPetropayAccountViewModel from "../view-models/PaymentPetropayAccountViewModel";
import ListPetropayAccountViewModel from "../../PetropayAccounts/view-models/ListPetropayAccountViewModel";
import GetPetropayAccountViewModel from "../view-models/GetPetropayAccountViewModel";
import UserContext from "identity/contexts/UserContext";

import NavigationService from "app/services/NavigationService";

export default class PetropayAccountStore
{
    paymentPetropayAccountViewModel: PaymentPetropayAccountViewModel;
    listPetropayAccountViewModel: ListPetropayAccountViewModel;
    getPetropayAccountViewModel: GetPetropayAccountViewModel;

    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onPetropayAccountGetPageLoad()
    {
        
        if( UserContext.info.role != 100  )
            {
               
        
                 NavigationService.navigate('/');
                
        
        }
        this.getPetropayAccountViewModel = new GetPetropayAccountViewModel(this);
        this.listPetropayAccountViewModel = new ListPetropayAccountViewModel();

    }

    onPetropayAccountGetPageUnload()
    {
        
        this.getPetropayAccountViewModel = null;
        this.listPetropayAccountViewModel = null;
    }

    onPetropayAccountPaymentPageLoad()
    {
        
        if(UserContext.info.role != 100  )
            {
               
        
                 NavigationService.navigate('/');
                
        
        }
        this.paymentPetropayAccountViewModel = new PaymentPetropayAccountViewModel(this);
        this.listPetropayAccountViewModel = new ListPetropayAccountViewModel();
    }

    onPetropayAccountPaymentPageUnload()
    {
        this.paymentPetropayAccountViewModel = null;
        this.listPetropayAccountViewModel = null;
    }

}
