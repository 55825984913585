import {makeAutoObservable} from "mobx";

export default class EditOilPriceRequest
{
   
    productID: number;
    productDescriptionEN: string;
    productDescriptionAR: string;
    productCostPrice: number;
    productSelingPrice: number;
   // productActivation: boolean;
    productLiter: number;
   // petrolCompanyid: number;
    petrol_Company_id : number;
    product_Active : boolean;


    constructor(
    ) {
        makeAutoObservable(this);
    }
}
