export default
[
    {title: "CustomerDashboard.CompanySubscriptionItem.Label.key", dataIndex: "key", key: "key", responsive: ['md']},
    {title: "CustomerDashboard.CompanySubscriptionItem.Label.subscriptionCarNumbers", dataIndex: "subscriptionCarNumbers", key: "subscriptionCarNumbers", responsive: ['md']},
    {title: "CustomerDashboard.CompanySubscriptionItem.Label.startDate", dataIndex: "startDate", key: "startDate", responsive: ['md']},
    {title: "CustomerDashboard.CompanySubscriptionItem.Label.endDate", dataIndex: "endDate", key: "endDate", responsive: ['md']},
    

   {title: "CustomerDashboard.CompanySubscriptionItem.Label.alarm", dataIndex: "alarm", key: "alarm", responsive: ['md']}
    

]

