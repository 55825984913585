import BundleItem from "../handlers/get/OilPriceItem";
import {makeAutoObservable} from "mobx";
import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import DeleteBundleHandler from "../handlers/delete/DeleteOilPriceHandler";
import {message} from "antd";
import OilPricesStore from "../stores/OilPricesStore";
import GetOilPriceRequest from "../handlers/get/GetOilPriceRequest";
import GetOilPriceHandler from "../handlers/get/GetOilPriceHandler";
import DeleteOilPriceRequest from "../handlers/delete/DeleteOilPriceRequest";
import DeleteOilPriceHandler from "../handlers/delete/DeleteOilPriceHandler";

export default class GetOilPriceViewModel {
    columns: any[];
    oilPriceList: BundleItem[];
    totalSize: number;
    isProcessing: boolean;
    errorMessage: string;
    pageIndex: number;
    pageSize: number;

    constructor(public oilPricesStore: OilPricesStore) {
        makeAutoObservable(this);

    }

    public async getAllOilPrices(getOilPricesRequest: GetOilPriceRequest) {
        try {
            this.isProcessing = true;
            let response = await GetOilPriceHandler.get(getOilPricesRequest);


            if (response && response.success) {

                let result = response.data;
                let items = result.items;
                this.oilPriceList = items;
                this.totalSize = result.totalCount;
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('Bundles.Error.Get.Message');
            log.error(e);
        } finally {
            this.isProcessing = false;
        }
    }
    public async deleteOilPrice(key: number)
    {
        try
        {

            this.errorMessage = "";
            let request = new DeleteOilPriceRequest();
            request.productID = key;
            let response = await DeleteOilPriceHandler.delete(request);

            if(response && response.success)
            {
                message.success(getLocalizedString(response.message));
                await this.getAllOilPrices(new GetOilPriceRequest(this.pageSize, this.pageIndex));
            }
            else{
                this.errorMessage = getLocalizedString(response.message);
                message.error(this.errorMessage);
            }
        }
        catch(e)
        {
            this.errorMessage = i18next.t('Bundles.Error.Delete.Message');
            message.error(this.errorMessage);
            log.error(e);
        }
        finally
        {
            this.isProcessing = false;
        }
    }

}
