import InvoiceReviewItem from "../handlers/get/InvoiceReviewItem";
import InvoiceReviewStore from "../stores/InvoiceReviewStore";
import {makeAutoObservable} from "mobx";
import GetInvoiceReviewRequest from "../handlers/get/GetInvoiceReviewRequest";
import GetInvoiceReviewHandler from "../handlers/get/GetInvoiceReviewHandler";
import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import {message, Result} from "antd";
import UserContext from "../../../identity/contexts/UserContext";
import ObjectHelper from "../../../app/utils/ObjectHelper";
import GetInvoiceReviewResponse from "../handlers/get/GetInvoiceReviewResponse";
import GetInvoiceDetailResponse from "reports/InvoiceDetails/handlers/get/GetInvoiceDetailResponse";
import Item from "antd/lib/list/Item";
import GetStationReportHandler from "reports/StationReports/handlers/get/GetStationReportHandler";

export default class GetInvoiceReviewViewModel {
    columns: any[];
    invoiceReviewList: InvoiceReviewItem[];
    invoiceReviewExport: InvoiceReviewItem[];
    totalSize: number;
    sumInvoiceAmount: number;
    sumServiceFeesAmount: number;
    isProcessing: boolean;
    errorMessage: string;

    invoiceReviewResponse: GetInvoiceReviewResponse;
    
    getInvoiceReviewesRequest: GetInvoiceReviewRequest = new GetInvoiceReviewRequest();
    

    

    constructor(public invoiceReviewStore: InvoiceReviewStore) {
        makeAutoObservable(this);

        
    }

    public async getAllInvoiceReview(getInvoiceReviewesRequest: GetInvoiceReviewRequest, exportToFile: boolean = false) {
        try {
            this.errorMessage = "";
            if(ObjectHelper.isNullOrEmptyWithDefaultExceptions(getInvoiceReviewesRequest, [])){
                this.errorMessage = i18next.t("General.Search.AtLeastOne");
                return;
            }
            this.isProcessing = true;
            if(exportToFile)
                getInvoiceReviewesRequest.exportToFile = exportToFile;
            let response = await GetInvoiceReviewHandler.get(getInvoiceReviewesRequest);

        //  this.invoicePumpPhoto="";

            if (response && response.success) {

                let result = response.data;
                let items = result.items;
                if(exportToFile)
                    this.invoiceReviewExport = items;
                else {
                    this.invoiceReviewList = items;
                    this.totalSize = result.totalCount;
                    this.sumInvoiceAmount = result.sumInvoiceAmount;
                    this.sumServiceFeesAmount = result.sumServiceFeesAmount;
                }
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('InvoiceReviewes.Error.Get.Message');
            log.error(e);
        } finally {
            getInvoiceReviewesRequest.exportToFile = false;
            this.isProcessing = false;
        }
    }

}
