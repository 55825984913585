export default class GetSubscriptionRequest
{
    pageSize: number;
    pageIndex: number;
    companyId: number;
    dateFrom: string;
    dateTo: string;
    status: number;
    exportToFile: boolean;

    constructor(

    ) {
    }
}
