export default [
    {title: "General.Region.Aswan", value: "Aswan"},
    {title: "General.Region.Alexandria", value: "Alexandria"},
    {title: "General.Region.Asyut", value: "Asyut"},
    {title: "General.Region.Beheira", value: "Beheira"},
    {title: "General.Region.BeniSuef", value: "BeniSuef"},
    {title: "General.Region.Cairo", value: "Cairo"},
    {title: "General.Region.Dakahlia", value: "Dakahlia"},
    {title: "General.Region.Damietta", value: "Damietta"},
    {title: "General.Region.Faiyum", value: "Faiyum"},
    {title: "General.Region.Gharbia", value: "Gharbia"},
    {title: "General.Region.Giza", value: "Giza"},
    {title: "General.Region.Ismailia", value: "Ismailia"},
    {title: "General.Region.KafrElSheikh", value: "KafrElSheikh"},
    {title: "General.Region.Luxor", value: "Luxor"},
    {title: "General.Region.Matruh", value: "Matruh"},
    {title: "General.Region.Minya", value: "Minya"},
    {title: "General.Region.Monufia", value: "Monufia"},
    {title: "General.Region.NewValley", value: "NewValley"},
    {title: "General.Region.NorthSinai", value: "NorthSinai"},
    {title: "General.Region.Port Said", value: "Port Said"},
    {title: "General.Region.Qalyubia", value: "Qalyubia"},
    {title: "General.Region.Qena", value: "Qena"},
    {title: "General.Region.RedSea", value: "RedSea"},
    {title: "General.Region.Sharqia", value: "Sharqia"},
    {title: "General.Region.Sohag", value: "Sohag"},
    {title: "General.Region.SouthSinai", value: "SouthSinai"},
    {title: "General.Region.Suez", value: "Suez"}
]
