export default
[
    /*{title: "Branches.Label.key", dataIndex: "key", key: "key", responsive: ['md']},*/
    {title: "Branches.Label.companyBranchName", dataIndex: "companyBranchName", key: "companyBranchName", responsive: ['md']},
    {title: "Branches.Label.companyBranchAdminName", dataIndex: "companyBranchAdminName", key: "companyBranchAdminName", responsive: ['md']},
    {title: "Branches.Label.companyBranchNumberOfcar", dataIndex: "companyBranchNumberOfcar", key: "companyBranchNumberOfcar", responsive: ['md']},
    {title: "Branches.Label.companyBranchActiva", dataIndex: "companyBranchActiva", key: "companyBranchActiva", responsive: ['md']},
    {title: "Branches.Label.companyBranchBalnce", dataIndex: "companyBranchBalnce", key: "companyBranchBalnce", responsive: ['md']},
    /*{title: "Branches.Label.companyBranchAddress", dataIndex: "companyBranchAddress", key: "companyBranchAddress", responsive: ['md']},
    {title: "Branches.Label.companyBranchAdminUserName", dataIndex: "companyBranchAdminUserName", key: "companyBranchAdminUserName", responsive: ['md']},
    {title: "Branches.Label.companyBranchAdminPhone", dataIndex: "companyBranchAdminPhone", key: "companyBranchAdminPhone", responsive: ['md']},
    {title: "Branches.Label.companyBranchAdminUserPassword", dataIndex: "companyBranchAdminUserPassword", key: "companyBranchAdminUserPassword", responsive: ['md']},
    {title: "Branches.Label.companyBranchAdminEmail", dataIndex: "companyBranchAdminEmail", key: "companyBranchAdminEmail", responsive: ['md']}*/
]

