import NavigationService from "app/services/NavigationService";
import {AppStore} from "app/stores/AppStore";
import UserContext from "identity/contexts/UserContext";
import {makeAutoObservable} from "mobx";
import EditBranchViewModel from "../view-models/EditBranchViewModel";
import GetBranchViewModel from "../view-models/GetBranchViewModel";

export default class BranchStore
{
    getBranchViewModel: GetBranchViewModel;
    editBranchViewModel: EditBranchViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onBranchGetPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 5 && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.getBranchViewModel = new GetBranchViewModel(this);
    }

    onBranchGetPageUnload()
    {
        this.getBranchViewModel = null;
    }

    onBranchEditPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 5 && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.editBranchViewModel = new EditBranchViewModel(this);
    }

    onBranchEditPageUnload()
    {
        this.editBranchViewModel = null;
    }

}
