import NavigationService from "app/services/NavigationService";
import {AppStore} from "app/stores/AppStore";
import UserContext from "identity/contexts/UserContext";
import {makeAutoObservable} from "mobx";
import GetCarBalanceViewModel from "../view-models/GetCarBalanceViewModel";

export default class CarBalanceStore
{
    getCarBalanceViewModel: GetCarBalanceViewModel;

    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onCarBalanceGetPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 5 && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.getCarBalanceViewModel = new GetCarBalanceViewModel(this);
    }

    onCarBalanceGetPageUnload()
    {
        this.getCarBalanceViewModel = null;
    }

}
