import CarUserItem from "../handlers/get/CarUserItem";
import AddCarUserRequest from "../handlers/add/AddCarUserRequest";
import CarUserStore from "../stores/CarUserStore";
import {makeAutoObservable} from "mobx";
import GetCarUserRequest from "../handlers/get/GetCarUserRequest";
import GetCarUserHandler from "../handlers/get/GetCarUserHandler";
import GetCarUserResponse from "../handlers/get/GetCarUserResponse";
import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import DeleteCarUserHandler from "../handlers/delete/DeleteCarUserHandler";
import DeleteCarUserRequest from "../handlers/delete/DeleteCarUserRequest";
import {message} from "antd";
import UserContext from "../../../identity/contexts/UserContext";

export default class GetCarUserViewModel {
    columns: any[];
    carUserList: CarUserItem[];
    totalSize: number;
    isProcessing: boolean;
    errorMessage: string;
    pageIndex: number;
    pageSize: number;
    carId: number;
    getCarUserRequest: GetCarUserRequest;

    constructor(public carUserStore: CarUserStore) {
        makeAutoObservable(this);

    }

    public async getAllCarUser(getCarUsersRequest: GetCarUserRequest) {
        try {
            this.isProcessing = true;
            let response = await GetCarUserHandler.get(getCarUsersRequest);


            if (response && response.success) {

                let result = response.data;
                let items = result.items;
                this.carUserList = items;
                this.totalSize = result.totalCount;
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('Bundles.Error.Get.Message');
            log.error(e);
        } finally {
            this.isProcessing = false;
        }
    }
    public async deleteCarUser(key: number)
    {
        try
        {

            this.errorMessage = "";
            let request = new DeleteCarUserRequest();
            request.carUserId = key;
            let response = await DeleteCarUserHandler.delete(request);

            if(response && response.success)
            {
                message.success(getLocalizedString(response.message));
                await this.getAllCarUser(new GetCarUserRequest(this.pageSize, this.pageIndex,this.carId));
            }
            else{
                this.errorMessage = getLocalizedString(response.message);
                message.error(this.errorMessage);
            }
        }
        catch(e)
        {
            this.errorMessage = i18next.t('Bundles.Error.Delete.Message');
            message.error(this.errorMessage);
            log.error(e);
        }
        finally
        {
            this.isProcessing = false;
        }
    }

}
