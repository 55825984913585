import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import EditEmployeeViewModel from "../view-models/EditEmployeeViewModel";
import GetEmployeeViewModel from "../view-models/GetEmployeeViewModel";
import TreeMenuViewModel from "../../menus/view-models/TreeMenuViewModel";
import EditEmployeeMenuViewModel from "../view-models/EditEmployeeMenuViewModel";
import UserContext from "identity/contexts/UserContext";

import NavigationService from "app/services/NavigationService";

export default class EmployeeStore
{
    getEmployeeViewModel: GetEmployeeViewModel;
    editEmployeeViewModel: EditEmployeeViewModel;

    treeMenuViewModel: TreeMenuViewModel;
    editEmployeeMenuViewModel: EditEmployeeMenuViewModel;

    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onEmployeeGetPageLoad()
    {
        if( UserContext.info.role !== 100 )
        {    
             NavigationService.navigate('/');
    
         }
        this.getEmployeeViewModel = new GetEmployeeViewModel(this);
    }

    onEmployeeGetPageUnload()
    {
        this.getEmployeeViewModel = null;
    }
    onEmployeeEditPageLoad()
    {
        if( UserContext.info.role !== 100 )
        {    
             NavigationService.navigate('/');
    
         }
        this.editEmployeeViewModel = new EditEmployeeViewModel(this);
    }

    onEmployeeEditPageUnload()
    {
        this.editEmployeeViewModel = null;
    }

    onEmployeeMenuEditPageLoad()
    {
        if( UserContext.info.role !== 100 )
        {    
             NavigationService.navigate('/');
    
         }
        this.editEmployeeMenuViewModel = new EditEmployeeMenuViewModel(this);
        this.treeMenuViewModel = new TreeMenuViewModel();
    }

    onEmployeeMenuEditPageUnLoad()
    {
        this.editEmployeeMenuViewModel = null;
        this.treeMenuViewModel = null;
    }

}
