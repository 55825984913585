import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import GetCustomerStatementViewModel from "../view-models/GetCustomerStatementViewModel";
import ListCompanyViewModel from "../../../entities/companies/view-models/ListCompanyViewModel";
import NavigationService from "app/services/NavigationService";
import UserContext from "identity/contexts/UserContext";

export default class CustomerStatementStore
{
    getCustomerStatementViewModel: GetCustomerStatementViewModel;
    listCompanyViewModel: ListCompanyViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onCustomerStatementGetPageLoad()
    {
        if( UserContext.info.role !== 1  && UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.getCustomerStatementViewModel = new GetCustomerStatementViewModel(this);
        this.listCompanyViewModel = new ListCompanyViewModel();
    }

    onCustomerStatementGetPageUnload()
    {
        this.getCustomerStatementViewModel = null;
        this.listCompanyViewModel = null;
    }

}
