import React from 'react';
import {inject, observer} from "mobx-react";
import Stores from "app/constants/Stores";

import {useParams} from "react-router-dom";
import {Button, Col, Divider, Form, Input, InputNumber, message, Modal, PageHeader, Radio, Row, Select, Spin, Switch, Upload} from "antd";
import i18next from "i18next";

import {
    PlusOutlined, EyeInvisibleOutlined, EyeTwoTone
} from '@ant-design/icons';
import history from "../../../../app/utils/History";

import OilPricesStore from 'entities/OliPrice/stores/OilPricesStore';
import AddOilPriceRequest from '../../handlers/add/AddOilPriceRequest';
import DetailOilPriceResponse from '../../handlers/detail/DetailOilPriceResponse';

const { Option } = Select;
const {useEffect} = React;

interface EditOilPriceProps {
    oilPricesStore?: OilPricesStore;
    match?: any;
}

const EditOilPrice: React.FC<EditOilPriceProps> = inject(Stores.oilPricesStore)(observer(({oilPricesStore, match}) =>
{
    const [dataFetched, setDataFetched] = React.useState(false);
    const [bundleId, setBundleId] = React.useState(0);
    const [switchChecked, setSwitchChecked] = React.useState(false);

    const [pricepackageOptions,  setPricePackageOptions] = React.useState([]);
    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };


    useEffect(() => {
        onLoad();
        return onUnload;
    }, []);

    async function onLoad()
    {
        oilPricesStore.onOilPriceEditPageLoad();
        let bundleIdParam = +match.params?.bundleId;

        if(bundleIdParam)
        {
            await oilPricesStore.editOilPriceViewModel.getDetailOilPrice(bundleIdParam);
        }
        else{
            oilPricesStore.editOilPriceViewModel.addOilPriceRequest = new AddOilPriceRequest();
            oilPricesStore.editOilPriceViewModel.detailOilPriceResponse = new DetailOilPriceResponse();
        }
        setBundleId(bundleIdParam);
        setDataFetched(true);

       

{/*
        await oilPricesStore.PricePackageViewModel.getPricePackage();
        
        let pricepackage = [];
        for (let item of oilPricesStore.PricePackageViewModel?.PricePackageResponse?.items) {
            pricepackage.push(<Option key={item.key} value={item.packageName}>{item.packageName}</Option>);
        }
            
       
        setPricePackageOptions(pricepackage);

        
    */}


    }

    let viewModel = oilPricesStore.editOilPriceViewModel;

    if(!viewModel) return;


    async function onFinish(values: any) {

        if(bundleId)
        {
            await viewModel.editOilPrice(viewModel.editOilPriceRequest);
        }
        else
        {
            await viewModel.addOilPrice(viewModel.addOilPriceRequest);
        }
        if(!viewModel.errorMessage)
            history.goBack();
    };

   
    
    function onUnload() {
        oilPricesStore.onOilPriceEditPageUnload();
        setDataFetched(false);
        setBundleId(0);
    }
    function onChanged(e){
        if(bundleId)
            oilPricesStore.editOilPriceViewModel.editOilPriceRequest[`${e.target.id}`] = e.target.value;
        else
        oilPricesStore.editOilPriceViewModel.addOilPriceRequest[`${e.target.id}`] = e.target.value;
    }

    function onSelectChanged(e, propName){

        if(bundleId)
        oilPricesStore.editOilPriceViewModel.editOilPriceRequest[`${propName}`] = e;
        else
        oilPricesStore.editOilPriceViewModel.addOilPriceRequest[`${propName}`] = e;
    }

    function onSwitchChange(e, propName){
   

        if(bundleId)
        oilPricesStore.editOilPriceViewModel.editOilPriceRequest[`${propName}`] = e;
        
        else
        oilPricesStore.editOilPriceViewModel.addOilPriceRequest[`${propName}`] = e;
    
    }


    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={bundleId ? `${i18next.t("OilPrices.Edit.HeaderText")} ${bundleId}` : i18next.t("OilPrices.Add.HeaderText")}
            />

            <Divider>{i18next.t("OilPrices.Section.GeneralInformation")}</Divider>
            {dataFetched ?
            <Form {...formItemLayout} layout={"vertical"} onFinish={onFinish} form={form}
                  key={"oilPrice"}
                 scrollToFirstError>
                <Row gutter={[24, 16]}>
                    <Col span={8}>
                <Form.Item name="productDescriptionAR" initialValue={viewModel?.detailOilPriceResponse?.productDescriptionAR}
                           key={"productDescriptionAR"}
                           label={i18next.t("OilPrices.Label.oilPricesProductDescriptionAR")}
                           rules={[
                               {
                                   required: true,
                                   message: i18next.t("OilPrices.Validation.Message.oilPricesNumberFrom.Required")
                               }
                           ]}>
                    <Input  onChange={onChanged}/>
                </Form.Item>
                    </Col>
                    <Col span={8}>
                <Form.Item name="productDescriptionEN" initialValue={viewModel?.detailOilPriceResponse?.productDescriptionEN}
                           key={"productDescriptionEN"}
                           label={i18next.t("OilPrices.Label.oilPricesProductDescriptionEN")}
                           rules={[
                               {
                                   required: true,
                                   message: i18next.t("Bundles.Validation.Message.bundlesNumberTo.Required")
                               }
                           ]}>
                    <Input onChange={onChanged}/>
                </Form.Item>
                    </Col>

                    <Col span={8}>
                <Form.Item name="productCostPrice" initialValue={viewModel?.detailOilPriceResponse?.productCostPrice}
                           key={"productCostPrice"}
                           label={i18next.t("OilPrices.Label.oilPricesProductCostPrice")}
                           >
                    <Input type={"number"} onChange={onChanged}/>
                </Form.Item>
                    </Col>
                    <Col span={8}>
                <Form.Item name="productSelingPrice" initialValue={viewModel?.detailOilPriceResponse?.productSelingPrice}
                           key={"productSelingPrice"}
                           label={i18next.t("OilPrices.Label.oilPricesProductSelingPrice")}>
                    <Input type={"number"} onChange={onChanged}/>
                </Form.Item>
                    </Col>
                    <Col span={8}>
                <Form.Item name="productLiter" initialValue={viewModel?.detailOilPriceResponse?.productLiter}
                           key={"productLiter"}
                           label={i18next.t("OilPrices.Label.oilPricesproductLiter")}>
                    <Input type={"number"} onChange={onChanged}/>
                </Form.Item>
                    </Col>

                
                
                        <Col span={8}>
                        < Form.Item name="petrol_Company_id" initialValue={viewModel?.detailOilPriceResponse?.petrol_Company_id}
                                    key={"petrol_Company_id"}
                                    label={i18next.t("OilPrices.Label.oilPricesPetrolCompanyid")}
                                  >

                                    <Input type={"number"} onChange={onChanged}/>
                           {/* <Select showSearch={true} onChange={(e) => onSelectChanged(e,"bundlesPricepackage")}>
                                {pricepackageOptions}
                                </Select>*/}
                        </Form.Item>
                    </Col>


                    <Col span={8}>
                        <Form.Item name="product_Active" initialValue={viewModel?.detailOilPriceResponse?.product_Active}
                                   key={"product_Active"}
                                   label={i18next.t("OilPrices.Label.oilPricesProductActivation")}>
                          <Switch onChange={(e) => onSwitchChange(e, 'product_Active')} defaultChecked={viewModel?.detailOilPriceResponse.product_Active} />
                        </Form.Item>
                    </Col>

                    


                    
                

                </Row>
                <Divider></Divider>
                {viewModel.errorMessage && (
                    <div className='response-error-msg'>{viewModel.errorMessage}</div>
                )}
                    <PageHeader
                        ghost={false}
                        extra={[
                            <Button type="primary" loading={viewModel.isProcessing} key="submit" size={"large"} htmlType="submit">
                                {i18next.t("General.Add.SaveButton")}
                            </Button>
                        ]}
                    />

            </Form>
                :
                <Row gutter={[24, 16]}>
                    <Col offset={11} span={8}>
                        <Spin className={"spine"} size="large" />
                    </Col>
                </Row>
            }
        </div>
    )
}));

export default EditOilPrice;


