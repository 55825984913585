// eslint-disable-next-line import/no-anonymous-default-export
export default
[ 
    {title: "AdminDashboard.CompanyListItem.Label.key", dataIndex: "key" , key: "key"},
    {title: "AdminDashboard.CompanyListItem.Label.name", dataIndex: "name", key: "name"},
    {title: "AdminDashboard.CompanyListItem.Label.balance" ,dataIndex: "balance",
    
    render: value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser:value => value!.replace(/\$\s?|(,*)/g, ''),
    key: "balance"},
    {title: "AdminDashboard.CompanyListItem.Label.SubscriptionEndDate", dataIndex: "endDate", key: "endDate", responsive: ['md']}

]

