import ApiResponse from "app/models/ApiResponse";
import ApiService from "app/services/ApiService";
import Endpoints from "app/constants/Endpoints";
import GetInvoiceReviewRequest from "./GetInvoiceReviewRequest";

export default class GetInvoiceReviewHandler
{
    public static async get(request: GetInvoiceReviewRequest): Promise<ApiResponse>
    {
        let response = await ApiService.post(Endpoints.apiInvoiceReviewGet, request, true);
        return response;
    }
}
