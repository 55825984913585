import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import { useHistory } from "react-router-dom";
import "./CarUserList.scss";
import Stores from "app/constants/Stores";

import {
    Button,
    Pagination,
    Table, Modal, PageHeader
} from "antd";
import {
    EditOutlined, DeleteOutlined,
    ExclamationCircleOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import i18next from "i18next";


import Routes from "../../../../app/constants/Routes";
import NavigationService from "../../../../app/services/NavigationService";


import CarUserColumns from './CarUserColumns';
import CarUserStore from 'entities/CarUser/stores/CarUserStore';
import GetCarUserRequest from 'entities/CarUser/handlers/get/GetCarUserRequest';
import UserContext from 'identity/contexts/UserContext';
import GetCarRequest from 'entities/cars/handlers/get/GetCarRequest';
import CarStore from 'entities/cars/stores/CarStore';



const { confirm } = Modal;


interface CarUserSidebarProps {
    carUserStore?: CarUserStore;
    match?: any;
}



  

const CarUserList: React.FC<CarUserSidebarProps> = inject(Stores.carUserStore)(observer(({carUserStore, match}) => {

    const [carId, setCarId] = React.useState(0);

    useEffect(() => {
        
        
        onLoad(GetCarRequest.carID);  // to filter thr page for the by car id 

        return onUnload;
    }, []);

    CarUserColumns.forEach(w => {
     w.title = i18next.t(w.title)
    });
    const columns: any[] = [...CarUserColumns, {
        title: i18next.t("General.Column.Action"),
        dataIndex: 'operation',
        key: 'action',
        width: "100px",
        render: (text, record) => (
            <div className="inline">

                    <Button type="primary" icon={<EditOutlined />} onClick={() => showEditPage(record)}
                            title={i18next.t("General.Button.Edit")} style={{ borderRadius:"5px"  }}/>
                <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)}
                        title={i18next.t("General.Button.Delete")} style={{ borderRadius:"5px" }}/>
            </div>
        )
    }];
    async function showEditPage(e){
       // carUserStore.editCarUserViewModel.key = e.key;
        if(e.key)
        {
            //await bundlesStore.editBundleViewModel.getDetailBundle(e.key);
            NavigationService.navigate(`/app/carUser/edit/${e.key}`); 
        }
        else{
            //bundlesStore.editBundleViewModel.addBundleRequest = new AddBundleRequest();
            NavigationService.navigate(Routes.addCarUser);
        }
    }
    async function showDeleteConfirm(e) {
        console.log(e.key);
        confirm({
            title: i18next.t("General.Confirm.Delete"),
            icon: <ExclamationCircleOutlined />,
            async onOk() {
                console.log(e.key);
                await onDelete(e.key);
            },
            onCancel() {},
        });
    }
    let viewModel = carUserStore.getCarUserViewModel;
    
    if (!viewModel) return;

    async function onDelete(key: number){
        await viewModel.deleteCarUser(key);
    }

    async function onLoad(key: number) {
        carUserStore.onCarUserGetPageLoad();

        let carIdParam =key ;
       
        //bundlesStore.onBundleEditPageLoad();
        carUserStore.getCarUserViewModel.pageIndex = 0;
        carUserStore.getCarUserViewModel.pageSize = 20;
       // carUserStore.getCarUserViewModel.carId = 0;
        
        await carUserStore.getCarUserViewModel.getAllCarUser(new GetCarUserRequest(20, 0, carIdParam));


    
     

        setCarId(carIdParam);




    }

    function onUnload() {
        carUserStore.onCarUserGetPageUnload();
        //bundlesStore.onBundleEditPageUnload();
    }

    async function pageIndexChanged(pageIndex, pageSize,carId){
        viewModel.pageIndex = pageIndex - 1;
        viewModel.pageSize = pageSize;
        viewModel.carId=carId;
        await carUserStore.getCarUserViewModel.getAllCarUser(new GetCarUserRequest(pageSize, pageIndex - 1,carId));
    }
    async function pageSizeChanged(current, pageSize){
        viewModel.pageIndex = 0;
        viewModel.pageSize = pageSize;
       
        await carUserStore.getCarUserViewModel.getAllCarUser(new GetCarUserRequest(pageSize, 0,0));
    }
    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                //title={i18next.t("CarUser.Page.Title")}
              //  subTitle={i18next.t("CarUser.Page.SubTitle")}
                title={carId ? `${i18next.t("CarUsers.Page.SubTitle")} ${carId}` : i18next.t("CarUsers.Page.SubTitle")}
                extra={[
                        <Button className={"button-section"} key={"Add"} type="primary" icon={<PlusCircleOutlined />} onClick={showEditPage}>
                            {i18next.t("General.Button.Add")}
                        </Button>
                    ,
                ]}
            />

            <Table dataSource={viewModel?.carUserList} columns={columns} loading={viewModel?.isProcessing}
                   bordered={true} pagination={false} sticky/>
            <br/>
            
        </div>
    )
}));


export default CarUserList;


