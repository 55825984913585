
import {makeAutoObservable} from "mobx";
import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import {message} from "antd";
import DetailOilPriceRequest from "../handlers/detail/DetailOilPriceRequest";
import AddOilPriceHandler from "../handlers/add/AddOilPriceHandler";
import AddOilPriceRequest from "../handlers/add/AddOilPriceRequest";
import DetailOilPriceHandler from "../handlers/detail/DetailOilPriceHandler";
import DetailOilPriceResponse from "../handlers/detail/DetailOilPriceResponse";
import EditOilPriceHandler from "../handlers/edit/EditOilPriceHandler";
import EditOilPriceRequest from "../handlers/edit/EditOilPriceRequest";
import OilPricesStore from "../stores/OilPricesStore";
import GetOilPriceRequest from "../handlers/get/GetOilPriceRequest";
;

export default class EditOilPriceViewModel
{
    isProcessing: boolean;
    errorMessage: string;
    key: number;
    uploadLoading: boolean;

    detailOilPriceResponse: DetailOilPriceResponse;
    addOilPriceRequest: AddOilPriceRequest;
    editOilPriceRequest: EditOilPriceRequest;
    

    constructor(public oilPricesStore:OilPricesStore) {
        makeAutoObservable(this);
    }
    public async getDetailOilPrice(productID: number)
    {
        try
        {
            this.errorMessage = "";
            this.isProcessing = true;

            let request = new DetailOilPriceRequest(productID);
            let response = await DetailOilPriceHandler.detail(request);

            if(response && response.success)
            {

                this.detailOilPriceResponse = new DetailOilPriceResponse().deserialize(response.data);
                this.editOilPriceRequest = new EditOilPriceRequest();
                for ( let i in this.editOilPriceRequest )
                    if ( this.detailOilPriceResponse.hasOwnProperty( i ) )
                        this.editOilPriceRequest[i] = this.detailOilPriceResponse[i];


                return this.detailOilPriceResponse;
            }
            else{
                this.errorMessage = getLocalizedString(response.message);
            }
        }
        catch(e)
        {
            this.errorMessage = i18next.t('Bundles.Error.Detail.Message');
            log.error(e);
        }
        finally
        {
            this.isProcessing = false;
        }
    }
    public async addOilPrice(request: AddOilPriceRequest)
    {
        try
        {
            this.errorMessage = "";
            this.isProcessing = true;

            let response = await AddOilPriceHandler.add(request);

            if(response && response.success)
            {
                message.success(getLocalizedString(response.message));
                /*await this.oilPricesStore.getOilPriceViewModel.getAllOilPrices(new GetOilPriceRequest(20, 0));*/
            }
            else{
                this.errorMessage = getLocalizedString(response.message);
            }
        }
        catch(e)
        {
            this.errorMessage = i18next.t('Bundles.Error.Add.Message');
            log.error(e);
        }
        finally
        {
            this.isProcessing = false;
        }
    }
    public async editOilPrice(request: EditOilPriceRequest)
    {
        try
        {
            this.errorMessage = "";
            this.isProcessing = true;

            let response = await EditOilPriceHandler.edit(request);

            if(response && response.success)
            {
                message.success(getLocalizedString(response.message));
                /*await this.bundlesStore.getBundleViewModel.getAllBundles(new GetBundlesRequest(20, 0));*/
            }
            else{
                this.errorMessage = getLocalizedString(response.message);
            }
        }
        catch(e)
        {
            this.errorMessage = i18next.t('Bundles.Error.Edit.Message');
            log.error(e);
        }
        finally
        {
            this.isProcessing = false;
        }
    }
}
