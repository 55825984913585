import {AppStore} from "app/stores/AppStore";
import {makeAutoObservable} from "mobx";
import EditOdometerRecordViewModel from "../view-models/EditOdometerRecordViewModel";
import GetOdometerRecordViewModel from "../view-models/GetOdometerRecordViewModel";
import ListCarViewModel from "../../cars/view-models/ListCarViewModel";
import UserContext from "identity/contexts/UserContext";
import NavigationService from "app/services/NavigationService";

export default class OdometerRecordStore
{
    getOdometerRecordViewModel: GetOdometerRecordViewModel;
    editOdometerRecordViewModel: EditOdometerRecordViewModel;

    listCarViewModel: ListCarViewModel;
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onOdometerRecordGetPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 5   )
        {    
             NavigationService.navigate('/');
    
         }
        this.getOdometerRecordViewModel = new GetOdometerRecordViewModel(this);
    }

    onOdometerRecordGetPageUnload()
    {
        this.getOdometerRecordViewModel = null;
    }

    onOdometerRecordEditPageLoad()
    {
        if( UserContext.info.role !== 1 && UserContext.info.role !== 5   )
        {    
             NavigationService.navigate('/');
    
         }
        this.editOdometerRecordViewModel = new EditOdometerRecordViewModel(this);
        this.listCarViewModel = new ListCarViewModel();
    }

    onOdometerRecordEditPageUnload()
    {
        this.editOdometerRecordViewModel = null;
        this.listCarViewModel = null;
    }

}
