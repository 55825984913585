import {makeAutoObservable} from "mobx";
import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import {message} from "antd";
import PricePackageRequest from "../handlers/list/PricePackageRequest";
import PricePackageHandler from "../handlers/list/PricePackageHandler";
import PricePackageResponse from "../handlers/list/PricePackageResponse";

export default class PricePackageViewModel {
    
    isProcessing: boolean;
    errorMessage: string;
    PricePackageResponse: PricePackageResponse;


    constructor() {
        makeAutoObservable(this);
    }

    public async getPricePackage()  {
        try {
            this.isProcessing = true;

            let request = new PricePackageRequest();
            let response = await PricePackageHandler.get(request);

            if (response && response.success) {
                
                this.PricePackageResponse = new PricePackageResponse();
                let result = response.data;
                this.PricePackageResponse.items = result;

                return this.PricePackageResponse;
            } else {
                this.errorMessage = getLocalizedString(response.message);
            }
        } catch (e) {
            this.errorMessage = i18next.t('Pricepackages.Error.List.Message');
            log.error(e);
        } finally {
            this.isProcessing = false;
        }
    }
}
