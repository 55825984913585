import IDeserialize from "app/interfaces/deserialize";

export default class DetailCarUserResponse implements IDeserialize
{
    key: number;
    carUserName: string;
    carUserEmpID :number;
  carUserPhoneNumber: string;
  carUserPassword: number;
 carID: number;
   // carUserMonthlyBudget: number;


    deserialize(input: any): this
    {
        Object.assign(this, input);
        return this;
    }
}
