export default
[
    /*{title: "AccountBalances.Label.key", dataIndex: "key", key: "key", responsive: ['md']},*/
    {title: "AccountBalances.Label.accountId", dataIndex: "accountId", key: "accountId", width: "100px"},
    {title: "AccountBalances.Label.accountTaype", dataIndex: "accountTaype", key: "accountTaype", responsive: ['md']},
    {title: "AccountBalances.Label.accountName", dataIndex: "accountName", key: "accountName", responsive: ['md']},
    {title: "AccountBalances.Label.sumTransAmount", dataIndex: "sumTransAmount",
    render: value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser:value => value!.replace(/\$\s?|(,*)/g, ''),
    
    key: "sumTransAmount", responsive: ['md']},

]

