import {makeAutoObservable} from "mobx";

export default class DeleteOilPriceRequest
{
    public productID: number;

    constructor(
    ) {
        makeAutoObservable(this);
    }
}
