import NavigationService from "app/services/NavigationService";
import {AppStore} from "app/stores/AppStore";
import UserContext from "identity/contexts/UserContext";
import {makeAutoObservable} from "mobx";
import GetAccountBalanceViewModel from "../view-models/GetAccountBalanceViewModel";

export default class AccountBalanceStore
{
    getAccountBalanceViewModel: GetAccountBalanceViewModel;

    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onAccountBalanceGetPageLoad()
    {
        if(  UserContext.info.role !== 100   )
        {    
             NavigationService.navigate('/');
    
         }
        this.getAccountBalanceViewModel = new GetAccountBalanceViewModel(this);
    }

    onAccountBalanceGetPageUnload()
    {
        this.getAccountBalanceViewModel = null;
    }

}
