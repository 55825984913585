import NavigationService from "app/services/NavigationService";
import {AppStore} from "app/stores/AppStore";
import PricePackageViewModel from "entities/PricePackages/view-models/PricePackageViewModel";

import UserContext from "identity/contexts/UserContext";


import {makeAutoObservable} from "mobx";

import EditBundleViewModel from "../view-models/EditBundleViewModel";
import GetBundleViewModel from "../view-models/GetBundleViewModel";

export default class BundlesStore
{
    getBundleViewModel: GetBundleViewModel;
    editBundleViewModel: EditBundleViewModel;

    PricePackageViewModel:PricePackageViewModel
 
   
    constructor(public appStore: AppStore) {
        makeAutoObservable(this);
    }

    onBundleGetPageLoad()
    {
        this.getBundleViewModel = new GetBundleViewModel(this);

        if( UserContext.info.role !== 100  )
            {
               
        
                 NavigationService.navigate('/');
                
        
        }
    }

    onBundleGetPageUnload()
    {
        this.getBundleViewModel = null;
    }

    onBundleEditPageLoad()
    {
        
        if( UserContext.info.role !== 100  )
        {
           
    
             NavigationService.navigate('/');
            
    
    }

        this.editBundleViewModel = new EditBundleViewModel(this);
        this.PricePackageViewModel = new PricePackageViewModel();

    }

    onBundleEditPageUnload()
    {
        this.editBundleViewModel = null;
        this.PricePackageViewModel=null;
        
    }

}
