import {makeAutoObservable} from "mobx";
import DetailCarUserResponse from "../handlers/detail/DetailCarUserResponse";
import GetCarUserHandler from "../handlers/get/GetCarUserHandler";
import {getLocalizedString} from "../../../app/utils/Localization";
import i18next from "i18next";
import log from "loglevel";
import DetailCarUserHandler from "../handlers/detail/DetailCarUserHandler";
import DetailCarUserRequest from "../handlers/detail/DetailCarUserRequest";
import AddCarUserRequest from "../handlers/add/AddCarUserRequest";
import EditCarUserRequest from "../handlers/edit/EditCarUserRequest";
import AddCarUserHandler from "../handlers/add/AddCarUserHandler";
import {message} from "antd";
import GetCarUserRequest from "../handlers/get/GetCarUserRequest";
import EditCarUserHandler from "../handlers/edit/EditCarUserHandler";
import UserContext from "../../../identity/contexts/UserContext";
import CarUserStore from "../stores/CarUserStore";

export default class EditCarUserViewModel
{
    isProcessing: boolean;
    errorMessage: string;
    key: number;
    uploadLoading: boolean;

    detailCarUserResponse: DetailCarUserResponse;
    addCarUserRequest: AddCarUserRequest;
    editCarUserRequest: EditCarUserRequest;

    constructor(public carUserStore: CarUserStore) {
        makeAutoObservable(this);
    }
    public async getDetailCarUser(carUserId: number)
    {
        try
        {
            this.errorMessage = "";
            this.isProcessing = true;

            let request = new DetailCarUserRequest(carUserId);
            let response = await DetailCarUserHandler.detail(request);

            if(response && response.success)
            {

                this.detailCarUserResponse = new DetailCarUserResponse().deserialize(response.data);
                this.editCarUserRequest = new EditCarUserRequest();
                for ( let i in this.editCarUserRequest )
                    if ( this.detailCarUserResponse.hasOwnProperty( i ) )
                        this.editCarUserRequest[i] = this.detailCarUserResponse[i];


                return this.detailCarUserResponse;
            }
            else{
                this.errorMessage = getLocalizedString(response.message);
            }
        }
        catch(e)
        {
            this.errorMessage = i18next.t('CarUseres.Error.Detail.Message');
            log.error(e);
        }
        finally
        {
            this.isProcessing = false;
        }
    }
    public async addCarUser(request: AddCarUserRequest)
    {
        try
        {
            this.errorMessage = "";
            this.isProcessing = true;

            /*request.stationId = UserContext.info.id;*/
            let response = await AddCarUserHandler.add(request);

            if(response && response.success)
            {
                message.success(getLocalizedString(response.message));
                /*await this.stationUsersStore.getCarUserViewModel.getAllCarUsers(new GetCarUsersRequest(20, 0));*/
            }
            else{
                this.errorMessage = getLocalizedString(response.message);
            }
        }
        catch(e)
        {
            this.errorMessage = i18next.t('CarUseres.Error.Add.Message');
            log.error(e);
        }
        finally
        {
            this.isProcessing = false;
        }
    }
    public async editCarUser(request: EditCarUserRequest)
    {
        try
        {
            this.errorMessage = "";
            this.isProcessing = true;

            let response = await EditCarUserHandler.edit(request);

            if(response && response.success)
            {
                message.success(getLocalizedString(response.message));
                /*await this.stationUsersStore.getCarUserViewModel.getAllCarUsers(new GetCarUsersRequest(20, 0));*/
            }
            else{
                this.errorMessage = getLocalizedString(response.message);
            }
        }
        catch(e)
        {
            this.errorMessage = i18next.t('CarUseres.Error.Edit.Message');
            log.error(e);
        }
        finally
        {
            this.isProcessing = false;
        }
    }
}
