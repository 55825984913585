import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import { useHistory } from "react-router-dom";
import "./OilPricesList.scss";
import Stores from "app/constants/Stores";

import {
    Button,
    Pagination,
    Table, Modal, PageHeader
} from "antd";
import {
    EditOutlined, DeleteOutlined,
    ExclamationCircleOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import i18next from "i18next";


import Routes from "../../../../app/constants/Routes";
import NavigationService from "../../../../app/services/NavigationService";

import OilPricesStore from 'entities/OliPrice/stores/OilPricesStore';
import OilPricesColumns from './OilPricesColumns';
import GetOilPriceRequest from 'entities/OliPrice/handlers/get/GetOilPriceRequest';


const { confirm } = Modal;


interface OilPricesSidebarProps {
    oilPricesStore?: OilPricesStore
}



const OilPricesList: React.FC<OilPricesSidebarProps> = inject(Stores.oilPricesStore)(observer(({oilPricesStore}) => {
    useEffect(() => {
        onLoad();

        return onUnload;
    }, []);

    OilPricesColumns.forEach(w => {
     w.title = i18next.t(w.title)
    });
    const columns: any[] = [...OilPricesColumns, {
        title: i18next.t("General.Column.Action"),
        dataIndex: 'operation',
        key: 'action',
        width: "100px",
        render: (text, record) => (
            <div className="inline">

                    <Button type="primary" icon={<EditOutlined />} onClick={() => showEditPage(record)}
                            title={i18next.t("General.Button.Edit")} />
                <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)}
                        title={i18next.t("General.Button.Delete")} />
            </div>
        )
    }];
    async function showEditPage(e){
        //bundlesStore.editBundleViewModel.key = e.key;
        if(e.key)
        {
            //await bundlesStore.editBundleViewModel.getDetailBundle(e.key);
            NavigationService.navigate(`/app/oilprice/edit/${e.key}`);
        }
        else{
            //bundlesStore.editBundleViewModel.addBundleRequest = new AddBundleRequest();
            NavigationService.navigate(Routes.addOilPrice);
        }
    }
    async function showDeleteConfirm(e) {
        console.log(e.key);
        confirm({
            title: i18next.t("General.Confirm.Delete"),
            icon: <ExclamationCircleOutlined />,
            async onOk() {
                console.log(e.key);
                await onDelete(e.key);
            },
            onCancel() {},
        });
    }
    let viewModel = oilPricesStore.getOilPriceViewModel;

    if (!viewModel) return;

    async function onDelete(key: number){
        await viewModel.deleteOilPrice(key);
    }

    async function onLoad() {
        oilPricesStore.onOilPriceGetPageLoad();
        //bundlesStore.onBundleEditPageLoad();
        oilPricesStore.getOilPriceViewModel.pageIndex = 0;
        oilPricesStore.getOilPriceViewModel.pageSize = 20;
        await oilPricesStore.getOilPriceViewModel.getAllOilPrices(new GetOilPriceRequest(20, 0));
    }

    function onUnload() {
        oilPricesStore.onOilPriceGetPageUnload();
        //bundlesStore.onBundleEditPageUnload();
    }

    async function pageIndexChanged(pageIndex, pageSize){
        viewModel.pageIndex = pageIndex - 1;
        viewModel.pageSize = pageSize;
        await oilPricesStore.getOilPriceViewModel.getAllOilPrices(new GetOilPriceRequest(pageSize, pageIndex - 1));
    }
    async function pageSizeChanged(current, pageSize){
        viewModel.pageIndex = 0;
        viewModel.pageSize = pageSize;
        await oilPricesStore.getOilPriceViewModel.getAllOilPrices(new GetOilPriceRequest(pageSize, 0));
    }
    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={i18next.t("OilPrices.Page.Title")}
                subTitle={i18next.t("OilPrices.Page.SubTitle")}
                extra={[
                        <Button key={"Add"} type="primary" icon={<PlusCircleOutlined />} onClick={showEditPage}>
                            {i18next.t("General.Button.Add")}
                        </Button>
                    ,
                ]}
            />

            <Table dataSource={viewModel?.oilPriceList} columns={columns} loading={viewModel?.isProcessing}
                   bordered={true} pagination={false} sticky/>
            <br/>
            <Pagination
                total={viewModel?.totalSize}
                showSizeChanger
                showQuickJumper
                defaultPageSize={20}
                onChange={pageIndexChanged}
                onShowSizeChange={pageSizeChanged}
                showTotal={total => `Total ${total} items`}
            />
        </div>
    )
}));


export default OilPricesList;


